<template>
  <div class="p-4 root relative block">
    <div class="font-h pr-2 font-semibold text-lg text-primary">Search</div>
    <div class="mt-4 relative pr-3">
      <input
        type="text"
        ref="searchInput"
        v-model="searchtermModel"
        @keyup.enter="research()"
        @focus="onInputFocus()"
        class="border rounded border-primary-300 text-sm w-full py-2.25 pr-10 pl-3 text-primary-800 focus:ring-primary focus:border-primary block"
      />
      <div
        class="cursor-pointer flex pr-5 inset-y-0 right-0 text-primary-600 absolute items-center hover:text-primary-700"
        @click="research()"
      >
        <SearchIcon classname="h-5 w-5" />
      </div>
    </div>

    <!-- result list -->
    <div v-if="searchResults.length" class="flex mt-1.5 text-xs mb-3 items-center justify-between">
      <div class="text-primary-700 italic">
        {{searchResults.length}} results found!
      </div>

      <div v-if="message" class="text-green">
        {{message}}
      </div>
    </div>

    <div v-if="searched && !searchResults.length" class="flex flex-col list mt-22 text-primary-500 items-center">
      <div>
        <FileAlertIcon />
      </div>
      <div class="text-sm text-center leading-6 w-56">
        No results match your search 😢 Try something else?
      </div>
    </div>

    <div v-if="!searched" class="flex flex-col list mt-22 text-primary-500 items-center">
      <div>
        <FileSearchIcon />
      </div>
      <div class="text-sm text-center leading-6 w-52">
        Click on the search icon above or hit enter to begin search ✌️
      </div>
    </div>

    <!-- TODO: move list item to be a common component -->
    <div v-if="searched && searchResults.length" class="list pr-2 pb-20 overflow-y-auto">
      <div v-for="(item, index) in searchResults" :key="item.key" class="group relative">
        <div
          :class="['mb-2 px-2.25 py-2.5 flex items-center justify-between rounded cursor-pointer border hover:border-primary', item.isDeleted ? 'bg-primary-100 border-primary-200' : 'border-primary-300', selected === item.key ? 'border-primary' : '']"
          @click="goToInstance(item.location, item.key)"
        >
          <div class="flex items-center listText">
            <div v-if="editMode === 'editMedia'" :class="['text-2xs rounded p-2 mr-2', item.isDeleted ? 'bg-primary-400 text-primary-700' : 'bg-primary text-white']">
              {{item.atTime}}
            </div>
            <div
              class="text-xs leading-6 whitespace-nowrap overflow-ellipsis overflow-hidden"
              :class="[item.isDeleted ? 'text-primary-600' : 'text-gray-500']"
            >
              <span :class="['highlight', item.isDeleted ? 'text-primary-400' : '']">
                {{item.searchWord}}
              </span>
              <span>
                {{item.stringWithoutSearchWord}}
              </span>
            </div>
          </div>
        </div>

        <div v-if="item.isDeleted" :class="['absolute z-10 cursor-pointer text-primary-400 hover:text-green', editMode === 'editMedia' ? 'editMediaActionIcon' : 'actionIcon' ]" @click="restoreWord(item.location, index, item.key)">
          <RestoreIcon class="h-4.5 w-4.5"/>
        </div>
        <div v-else :class="['absolute z-10  cursor-pointer text-primary-400 hover:text-red', editMode === 'editMedia' ? 'editMediaActionIcon' : 'actionIcon']" @click="deleteWord(item.location, index, item.key)">
          <DeleteIcon class="h-4.5 w-4.5"/>
        </div>
      </div>
    </div>

    <!-- Action buttons section -->
    <div v-if="searchResults.length" class="bg-white p-2 right-0 bottom-0 left-0 z-10 topShadow absolute">
      <div v-if="editMode === 'editMedia'" class="flex">
        <Button
          variant="secondary"
          classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
          :onClick="restoreAll"
        >
          Restore all
        </Button>

        <Button
          classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
          :onClick="deleteAll"
        >
          Delete all
        </Button>
      </div>

      <div v-else>
        <div v-if="isReplacing">
          <div class="flex text-sm mb-1.5 leading-6 items-center">
            Replace <div class="mx-1 leading-6 highlightedText">{{searchterm}}</div> with:
          </div>

          <input type="text" v-model="replaceTextModel" class="border rounded border-primary-300 text-sm mb-2.5 w-full py-2.25 px-3 text-primary-800 focus:ring-primary focus:border-primary block" />
        </div>

        <div class="flex" v-if="!isReplacing && !confirmReplace">
          <Button
            variant="secondary"
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="toggleReplaceAllDialog"
          >
            Replace all
          </Button>

          <Button
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="deleteAll"
          >
            Delete all
          </Button>
        </div>

        <div class="flex" v-if="isReplacing">
          <Button
            variant="secondary"
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="toggleReplaceAllDialog"
          >
            Cancel
          </Button>

          <Button
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="replaceAll"
          >
            Replace Text
          </Button>
        </div>

        <div class="flex" v-if="confirmReplace">
          <Button
            variant="secondary"
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="undoReplaceAll"
          >
            undo all
          </Button>

          <Button
            classname="w-full justify-center ml-1.25 mr-1.25 first:ml-0 last:mr-0"
            :onClick="confirmReplaceAll"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  searchHighlightInDoc,
  removeAllSearchHighlights, // remove all search highlights
  deleteWordAtLocations,
  restoreWordAtLocations,
  replaceAll,
  replaceWordAtLocations,
  addCustomHighlight,
  removeAllSelections, searchHighlightInDocWithResults
} from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'

// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../proseEditor/util/editorSearchUtilityWorker'
import SearchIcon from '@/components/base/icons/Search.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import RestoreIcon from '@/components/base/icons/Restore.vue'
import FileSearchIcon from '@/components/base/icons/FileSearch.vue'
import FileAlertIcon from '@/components/base/icons/FileAlert.vue'
import Button from '@/components/base/buttons/Button.vue'
import { humanLength } from '@/utilities/utility'
import { myProseEditor, scrollToLocation } from '@/view/voiceEditor/proseEditor/util/utility'

let editorSearchUtilityWorker = worker()

const getResultsForUI = (list) => list.map(t => ({
  ...t,
  atTime: humanLength(Math.round(t.atTime)),
  key: `${t.location.start}-${t.location.end}`,
  stringWithoutSearchWord: t.resultString.replace(t.searchWord, '')
}))

export default {
  data: function() {
    return {
      searchterm: '',
      searched: '',
      searchResults: [],
      message: null,
      timeout: null,
      replaceText: '',
      isReplacing: false,
      confirmReplace: null,
      selected: null
    }
  },
  components: {
    SearchIcon,
    DeleteIcon,
    RestoreIcon,
    Button,
    FileAlertIcon,
    FileSearchIcon
  },
  computed: {
    ...mapGetters({
      'editMode': 'editor/editMode'
    }),
    searchtermModel: {
      get: function() {
        return this.searchterm
      },
      set: function(val) {
        const words = val.split(/\s+/)
        if (words.length > 4) return null
        if (!val.trim()) {
          this.searchResults = []
          this.searched = ''
        }
        this.searchterm = val
      }
    },
    replaceTextModel: {
      get: function() {
        return this.replaceText
      },
      set: function(val) {
        this.replaceText = val
      }
    }
  },
  methods: {
    onInputFocus: function() {
      this.$refs.searchInput.select()
    },
    research: function(searchterm) {
      this.$refs.searchInput.blur()
      const search = searchterm || this.searchterm
      removeAllSearchHighlights()
      const t0 = performance.now()
      editorSearchUtilityWorker.multipleSearchInDocWorker(myProseEditor.view.state.doc.toJSON(), [search]).then(results => {
        this.searchResults = getResultsForUI(searchHighlightInDocWithResults(search, results))
        this.searched = search
      })
      const t1 = performance.now()
      console.log('Call to editorSearchUtilityWorker took ' + (t1 - t0) + ' milliseconds.')

      // this.searchResults = getResultsForUI(searchHighlightInDocWithResults(search, results))
      // console.log(this.searchResults)
      // this.searched = search
    },
    goToInstance: function(location, key) {
      removeAllSelections()
      scrollToLocation(location)
      addCustomHighlight(location.start, location.end, 'searchSelection', 'searchSelection')
      this.selected = key
    },
    deleteWord: function(location, index, key) {
      deleteWordAtLocations([location])
      this.$set(this.searchResults, index, {
        ...this.searchResults[index],
        isDeleted: true
      })
      this.setMessage('Deleted Successfully')
      this.goToInstance(location, key)
    },
    restoreWord: function(location, index) {
      restoreWordAtLocations([location])
      this.$set(this.searchResults, index, {
        ...this.searchResults[index],
        isDeleted: false
      })
      this.setMessage('Restored Successfully')
    },
    replaceWord: function(location, index) {
      // replaceWordAtLocations([location])
    },
    deleteAll: function() {
      const toDelete = this.searchResults.filter(t => !t.isDeleted).map(t => t.location)
      if (toDelete) {
        deleteWordAtLocations(toDelete)
        this.searchResults = this.searchResults.map(t => ({ ...t, isDeleted: true }))
        this.setMessage('Deleted Successfully')
      }
    },
    restoreAll: function() {
      const toRestore = this.searchResults.filter(t => t.isDeleted).map(t => t.location)
      if (toRestore.length) {
        restoreWordAtLocations(toRestore)
        this.searchResults = this.searchResults.map(t => ({ ...t, isDeleted: false }))
        this.setMessage('Restored Successfully')
      }
    },
    setMessage: function(message, duration = 800) {
      clearTimeout(this.timeout)
      this.message = message
      this.timeout = setTimeout(() => {
        this.message = ''
      }, duration)
    },
    toggleReplaceAllDialog: function () {
      this.isReplacing = !this.isReplacing
    },
    replaceAll: function() {
      if (this.replaceText) {
        const confirmReplace = {
          oldText: this.searchterm,
          newText: this.replaceText
        }
        replaceAll(this.searchterm, this.replaceText)

        // update search
        this.searchterm = this.replaceText
        this.research(this.replaceText)

        // cleanup
        this.isReplacing = false
        this.replaceText = ''
        // success message
        this.setMessage('Replaced Successfully', 2000)

        // Go to confirm state
        this.confirmReplace = confirmReplace
      }
    },
    undoReplaceAll: function() {
      const { oldText, newText } = this.confirmReplace
      replaceAll(newText, oldText)

      // update search
      this.searchterm = oldText
      this.research(oldText)

      // cleanup
      this.confirmReplace = null
      // success message
      this.setMessage('Replace undone', 2000)
    },
    confirmReplaceAll: function() {
      this.confirmReplace = null
    }
  },
  beforeDestroy: function() {
    removeAllSearchHighlights()
  },
  mounted () {
    this.$refs.searchInput.focus()
  }
}
</script>

<style scoped lang="css">
.root {
  height: 100%;
}

.list {
  height: calc(100% - 118px);
}

.editMediaActionIcon {
  top: 16px;
  right: 14px;
}

.actionIcon {
  top: 14px;
  right: 14px;
}

.listText {
  width: calc(100% - 32px);
}

.highlight {
  padding: 3px 0 3px 3px;
  margin-right: 2px;
  border-radius: 2px;
  background: rgba(248, 196, 80, 0.5);
  box-shadow: 0px 4px 4px rgba(248, 196, 80, 0.2);
}

.highlightedText {
  padding: 0 3px;
  border-radius: 2px;
  background: rgba(248, 196, 80, 0.5);
  box-shadow: 0px 4px 4px rgba(248, 196, 80, 0.2);
}

.topShadow {
  box-shadow: 0px -8px 8px rgba(0, 1, 20, 0.08);
}
</style>
